<template>
  <div class="nursery" v-if="hasPerm('nursery.view_nurseryinscription')">
    <page-header
      title="Export Filoue"
      icon="fa fa-file-csv"
    ></page-header>
    <b-row>
      <b-col cols="8">
        <div class="filoue-form">
          <b-row>
            <b-col>
              <b-form-group label="Lieu" label-for="nurseries">
                <choices-select
                  id="nurseries"
                  :choices="nurseries"
                  v-model="selectedNursery"
                  :callback="getNurseryLabel"
                >
                </choices-select>
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group label="noms et prénoms" label-for="nurseries">
                <b-form-checkbox id="includeNames" v-model="includeNames">
                  Inclure
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group label="Année" label-for="nurseries">
                <choices-select
                  id="years"
                  :choices="years"
                  v-model="year"
                >
                </choices-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-right">
              <div class="filoue-btn">
                <a href @click.prevent="loadReport()" class="btn btn-primary" :class="{ disabled: !isValid }">
                  <i class="fa fa-file-csv"></i> Générer le fichier FILOUE
                </a>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-col>
      <b-col>
        <div class="warning-text">
          <i class="fa fa-warning"></i>
          Cette fonctionnalité est en cours de validation.<br /><br />
          Merci de vérifier les données dans l'export et nous confirmer
          qu'elles correspondent à ce qui est attendu.
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapMutations } from 'vuex'
import CounterLabel from '@/components/Controls/CounterLabel.vue'
import LoadingGif from '@/components/Controls/LoadingGif'
import PageHeader from '@/components/Layout/PageHeader'
import DateFrameSelector from '@/components/DateRange/DateFrameSelector.vue'
import ChoicesSelect from '@/components/Controls/ChoicesSelect.vue'
import { currency, dateToString } from '@/filters/texts'
import { BackendMixin } from '@/mixins/backend'
import { makeSocialRegime } from '@/types/families'
import { makeIndividual } from '@/types/people'
import { makeNursery } from '@/types/nursery'
import { getMonthRange } from '@/utils/dates'
import { BackendApi, openDocument } from '@/utils/http'
import { sum } from '@/utils/math'
import { makeSchoolYear } from '@/types/schools'
import { distinct } from '@/utils/arrays'

export default {
  name: 'nursery-filoue-report',
  mixins: [BackendMixin],
  components: {
    ChoicesSelect,
    PageHeader,
  },
  props: {
  },
  data() {
    return {
      nurseries: [],
      selectedNursery: null,
      years: [],
      year: null,
      includeNames: false,
    }
  },
  watch: {
  },
  computed: {
    isValid() {
      return !!(this.selectedNursery && this.year)
    },
  },
  async mounted() {
    await this.loadNurseries()
    await this.loadActiveSchoolYears()
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    getNurseryLabel(elt) {
      return elt.getLabel()
    },
    onDateRangeChanged(event) {
      this.startDate = event.startDate
      this.endDate = event.endDate
      this.loadReport()
    },
    async loadActiveSchoolYears() {
      const backendApi = new BackendApi('get', '/api/active-school-years/')
      try {
        let resp = await backendApi.callApi()
        const schoolYears = resp.data.map(elt => makeSchoolYear(elt))
        const years = []
        for (const schoolYear of schoolYears) {
          years.push({ id: schoolYear.startYear, name: '' + schoolYear.startYear, })
          years.push({ id: schoolYear.startYear + 1, name: '' + (schoolYear.startYear + 1), })
        }
        this.years = distinct(years)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadNurseries() {
      let url = '/nursery/api/nurseries/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.nurseries = resp.data.map(elt => makeNursery(elt))
        if (this.nurseries.length === 1) {
          this.selectedNursery = this.nurseries[0]
        }
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
    async loadReport() {
      if (this.isValid) {
        const docUrl = '/nursery/filoue/<key>/'
        const docSlug = '' + this.selectedNursery.id + '-' + this.year.id + '-' + (this.includeNames ? '1' : '0')
        try {
          await openDocument(docUrl, docSlug)
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
  },
}
</script>

<style lang="less" scoped>
.filoue-form {
  padding: 20px;
  background: #eee;
  border-radius: 4px;
  border: solid 1px #888;
}
.filoue-btn {
  margin-top: 38px;
}
.dark-mode {
  .filoue-form {
    background: #444;
    border: solid 1px #888;
    color: #fff;
  }
}
</style>
